<template>
    <div v-loading="$wait.is('loading')" class="card self-start">
        <h1 class="border-b pb-3 border-gray-400">
            {{ $t('dashboard.traffic_information_from') }} sl.se
        </h1>

        <div class="mb-10 mt-5">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['fas', 'subway']" fixed-width />
                </p>
                <p class="ml-4">
                    Tunnelbana
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!metroData.length">
                    - Inga problem
                </p>
                <p v-for="event in metroData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
        <div class="my-10">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['far', 'train']" fixed-width />
                </p>
                <p class="ml-4">
                    Lokalbana
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!localData.length">
                    - Inga problem
                </p>
                <p v-for="event in localData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
        <div class="my-10">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['fal', 'train']" fixed-width />
                </p>
                <p class="ml-4">
                    Spårvagn
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!tramData.length">
                    - Inga problem
                </p>
                <p v-for="event in tramData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
        <div class="my-10">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['fas', 'bus']" fixed-width />
                </p>
                <p class="ml-4">
                    Buss
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!busData.length">
                    - Inga problem
                </p>
                <p v-for="event in busData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
        <div class="my-10">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['fas', 'train']" fixed-width />
                </p>
                <p class="ml-4">
                    Pendeltåg
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!trainData.length">
                    - Inga problem
                </p>
                <p v-for="event in trainData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
        <div class="my-10">
            <div class="flex items-center">
                <p class="text-2xl w-12 h-12 items-center justify-center flex bg-red-500 rounded-full text-white">
                    <fa-icon :icon="['fas', 'ship']" fixed-width />
                </p>
                <p class="ml-4">
                    Båt
                </p>
            </div>
            <div class="pl-16">
                <p v-if="!ferData.length">
                    - Inga problem
                </p>
                <p v-for="event in ferData" v-else :key="event.event_id" class="mb-4">
                    - {{ event.message }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            trafficData: {},
        };
    },

    computed: {
        metroData() {
            return this.trafficData?.metro?.events || [];
        },
        localData() {
            return this.trafficData?.local?.events || [];
        },
        tramData() {
            return this.trafficData?.tram?.events || [];
        },
        ferData() {
            return this.trafficData?.fer?.events || [];
        },
        busData() {
            return this.trafficData?.bus?.events || [];
        },
        trainData() {
            return this.trafficData?.train?.events || [];
        },
    },

    beforeDestroy() {
        axios.cancelAll();
    },

    created() {
        this.getData();
    },

    methods: {
        async getData() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/ai/traffic_conditions`, {
                requestId: 'justRequest',
            });
            this.trafficData = data;
            this.$wait.end('loading');
        },
    },
};
</script>
